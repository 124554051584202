<template>
    <div class="contaner_e">
        <div class="earnings_clone" @click="cloneBtn">
          <img style="width:15px;height:15px" src="../../assets/leftBtn.png"/>
            <div>返回</div>
        </div>
        <div class="class_info">
            <div>余额:</div>
            <div class="price_e">{{headerData.user.union_shareholder_sales}}</div>
            <!-- <div class="getBtn">提取</div> -->
        </div>
        <div class="item_text">
            <div class="style_size1">请输入金额:</div>
            <input  v-model="form.price"/>
            <div class="olny" @click="onBtn">全部</div>
        </div>
        <div class="message_title">提现信息</div>
        <div class="form_list">
            <div class="username">姓名:</div>
            <input v-model="form.userName"/>
        </div>
        <div class="form_list">
            <div class="username">开户银行:</div>
            <input v-model="form.bank"/>
        </div>
        <div class="form_list">
            <div class="username">开户行信息:</div>
            <input v-model="form.bankMessage"/>
        </div>
        <div class="form_list">
            <div class="username">银行卡号:</div>
            <input v-model="form.bankNumber"/>
        </div>

        <div class="pop_box" v-if="!headerData.user.union_info.bank" @click="detalsMessage">信息异常点击修改></div>
        <div class="form_list">
            <div class="username">手机验证：</div>
            <div class="input_number">{{headerData.user.member.member_mobile.slice(0,3)+'****'+headerData.user.member.member_mobile.slice(8,11)}}</div>
            <captcha @captchaPop="captchaPop" @propShow="propShow" ref='registerCaptcha' :config="captchaConfig" class="captcha_btn codeBox1 s_bg_11" slot="button"></captcha>
        </div>
        <div class="form_list">
            <div class="username">验证码:</div>
            <input v-model="form.code"/>
        </div>
        <div class="submitBtn" @click="submitBtn">提交</div>
    </div>
</template>
<script>
import { Toast } from "vant";
import captcha from "@/components/captcha";
import { withdrawal_api ,getWithdrawal_api} from "@/api/deal";
export default {
  data() {
    return {
      headerData:{
        user:{
          member:{}
        }
      },
      form:{
        userName:'',
        price:'',
        bank:'',
        bankMessage:'',
        bankNumber:'',
        code:'' 
      },captchaConfig: {
        title: "获取验证码",
        mobile: "",
        type: 6,
        code: "",
        imgToken: "",
        sales:true
        
      },
      submitFlay:true
    };
  },
  components:{
    captcha
  },
  created() {
    document.title = "收益中心";
    this.getList()
  },
  methods: {
    onBtn(){
      this.form.price = this.headerData.user.union_shareholder_sales
    },
    cloneBtn(){
      this.$router.go(-1)
    },
    detalsMessage(){
      this.$router.push("../deal/mine")
    },
    submitBtn(){
      if(!this.submitFlay) return false;
      this.submitFlay = false;
      let data = {
        cash_info:{
          userName:this.form.userName,
          bank:this.form.bank,
          bankMessage:this.form.bankMessage,
          bankNumber:this.form.bankNumber,
          
        },
        cash_amount:this.form.price,
        code:this.form.code,
        mobile:this.captchaConfig.mobile,
      }
      console.log(this.form.price<0)
      // 判断表单
      for(var i in this.form){
        if(!this.form[i]){
          Toast({
            message: "请完善表单",
            icon:'error',
            duration:1000
          })
          this.submitFlay = true
          return false
        }
        
      }
      console.log(Number(this.form.price),0)
      if(Number(this.form.price)<1){
          Toast({
            message: "请输入正确的金额",
            icon:'error',
            duration:1000
          })
          this.submitFlay = true
          return false
      }
      getWithdrawal_api(data).then(res=>{
        this.submitFlay = true
        if(res.code==0){
          Toast({
            message: "提交成功",
            icon: "success",
            duration:1000
          });
          setTimeout(() => {
              this.$router.go(-1)
          },1000)
        }else{
          Toast({
            message: res.error,
            icon:'error',
            duration:1000
          })
        }
      })
    },
    captchaPop() {
      Toast({
            message: "发送成功",
            icon: "success",
            duration:1000
          });
    },
    propShow(message) {
      Toast({
            message: message,
            icon: "success",
            duration:1000
          });
    },
    getList(){
      withdrawal_api().then(res=>{
        if(res.code==0){
          this.headerData = res.pagination;
          this.captchaConfig.mobile = this.headerData.user.member.member_mobile; 
          this.form.userName = this.headerData.user.union_info.bank.userName;
          this.form.bank = this.headerData.user.union_info.bank.bank;
          this.form.bankMessage = this.headerData.user.union_info.bank.address;
          this.form.bankNumber = this.headerData.user.union_info.bank.number;
          console.log(this.headerData.user.member)
        }
      })
    },
  }
};
</script>
<style>
.van-toast{
  width:300px;
  min-height:120px
}
.van-toast__text{
  font-size: 35px
}
.van-icon{
  font-size: 40px
}
.van-dialog {
  width: 500px;
  font-size: 35px !important;
}
.van-dialog__message {
  font-size: 25px;
  line-height: 40px;
}
.codeBox1 {
  width: 195px;
  height: 60px;
  border-radius: 5px;
  /* border: 2px dashed #fff; */
  font-size: 25px;
  background-color: #000;
  font-family: PingFang SC;
  font-weight: 400;
  color: #fff;
  opacity: 1;
  text-align: center;
  line-height: 60px;
  margin-left: 20px;
}
.contaner_e {
}

.earnings_clone {
  margin-left: 31px;
  color: #000000;
  font-size: 15px;
  font-weight: 800;
  margin-top: 30px;
}

.olny {
  font-size: 25px;
  color: #3377ff;
  margin: 50px 0 0 20px;
}

.item_text input {
  border: none;
  width: 305px;
  height: 80px;
  border-bottom: 1px solid #e6e6e6;
  opacity: 1;
  margin-left: 31px;
}

.class_info .getBtn {
  width: 97px;
  height: 52px;
  background: #2e2e30;
  box-shadow: 0px 0px 15px rgba(46, 46, 48, 0.25);
  opacity: 1;
  border-radius: 5px;
  text-align: center;
  line-height: 52px;
  font-size: 25px;
  color: #fff;
}
.class_info {
  display: flex;
  font-size: 40px;
  margin: 33px 0 33px 31px;
}
.price_e {
  margin: 0 15px;
  color: #dbb476;
}

.item_text .style_size1 {
  font-size: 30px;
  color: #2e2e30;
  margin: 45px 0 37px 0px;
  /* font-weight: 800; */
  display: flex;
}
.item_text {
  display: flex;
  margin-left: 79px;
}
.message_title {
  font-size: 35px;
  color: #2e2e30;
  font-weight: 800;
  margin: 51px 0 30px 35px;
}
.form_list {
  display: flex;
  font-size: 30px;
  font-weight: 400;
  margin: 30px 0
}
.form_list input {
  width: 494px;
  height: 65px;
  background: #ffffff;
  border: 1px solid #dbb476;
  opacity: 1;
  border-radius: 5px;
  font-size: 35px
}
.username {
  width: 180px;
  text-align: right;
  margin-right: 20px;
  margin-top: 20px;
}
.getcode {
  width: 169px;
  height: 52px;
  background: #2e2e30;
  box-shadow: 0px 0px 15px rgba(46, 46, 48, 0.25);
  opacity: 1;
  border-radius: 5px;
  text-align: center;
  line-height:52px;font-size: 25px;color:#fff;
  margin-left:30px;
  margin-top: 20px
}
.input_number{
    font-size: 30px ;font-weight: 400;
    color:#2E2E30;
    margin-top: 20px
}
.pop_box{
    font-size: 30px;
    color:#FF5E5E;
    margin-left:243px
}
.submitBtn{
    width: 628px;
height: 100px;
background: #DBB476;
opacity: 1;
border-radius: 10px;
font-size: 35px;
color:#fff;
text-align: center;
line-height:100px;
margin: 50px auto 50px
}
input{
  padding-left: 10px
}
</style>
